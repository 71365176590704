import { useHttpClient } from '@agyt/client/web/core/http';
import { AccountDetailsResponse, SuccessApiResponse } from '@agyt/shared/types';
import { SupportedCurrency } from '@agyt/shared/util/common';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

const ACCOUNT_DETAILS_CACHE_KEY = 'account-details';

export function useGetAccountDetails({
  currency,
}: {
  currency?: SupportedCurrency;
}) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [ACCOUNT_DETAILS_CACHE_KEY, currency],
    enabled: false,
    async queryFn() {
      const res = await httpClient.get(`/account-currencies/${currency}`);
      return res?.data as SuccessApiResponse<AccountDetailsResponse[]>;
    },
    staleTime: 30 * 1000,
    placeholderData: keepPreviousData,
  });
}
