import { Link } from '@tanstack/react-router';

import { cn } from '@agyt/client/shared/util/theme';

export type DetailsCardData = {
  key: string;
  value: string;
  details?: string;
  href?: string;
};

export function DetailsCard({
  data,
  className,
}: {
  data: DetailsCardData[];
  className?: string;
}) {
  return (
    <div
      className={cn(
        `mt-4 rounded-lg border border-slate-200 bg-white px-4 py-2`,
        className,
      )}
    >
      <ul>
        {data?.map((row, index: number) => (
          <li
            className={cn('flex flex-col py-2', {
              'border-b border-slate-200': index !== data.length - 1,
            })}
            key={row.key}
          >
            <div className="flex w-full items-center justify-between">
              <span className="mr-4 flex-shrink-0 text-sm font-medium leading-6 text-slate-400">
                {row.key}
              </span>
              {row?.href && (
                <Link to={row.href} className="cursor-pointer hover:underline">
                  <span className="text-sm font-medium leading-6 text-slate-900">
                    {row.value}
                  </span>
                </Link>
              )}
              {!row?.href && (
                <span className="overflow-x-auto text-sm font-medium leading-6 text-slate-900">
                  {row.value}
                </span>
              )}
            </div>
            {row.details && (
              <div className="flex justify-end">{row.details}</div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
