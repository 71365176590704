import { Link, useParams } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  DetailsCard,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  PageWrapper,
  Skeleton,
  toast,
  TransactionStatusBadge,
} from '@agyt/client/shared/ui/components';
import { useUser } from '@agyt/client/web/core/user';
import {
  useCancelConversion,
  useCancelPayment,
  useGetTransactionDetails,
} from '@agyt/client/web/data-access/api';
import {
  CcPaymentEvent,
  CcPaymentTracking,
  TransactionCriteria,
  TransactionDetailsResponse,
  TransactionResponse,
} from '@agyt/shared/types';
import {
  getCountryNameFromCode,
  LocalDate,
  Money,
  SupportedCurrency,
} from '@agyt/shared/util/common';
import { DateTime, Duration } from 'luxon';
import { useFilters } from './transactions-table-filters';

const transactionDetailOrderMap = {
  action: 0,
  amount: 1,
  reason: 2,
  status: 3,
  paymentReference: 4,
  relatedEntityShortReference: 5,
  paymentFee: 6,
  createdAt: 7,
  completedAt: 8,
};

const beneficiaryDetailsOrderMap = {
  beneficiaryType: 0,
  iban: 1,
  bic: 2,
  beneficiaryAddress: 3,
  beneficiaryCity: 4,
  beneficiaryCountry: 5,
};

const conversionDetailsOrderMap = {
  buyCurrency: 0,
  sellCurrency: 1,
  status: 2,
  fixedSide: 3,
  clientRate: 4,
  createdAt: 5,
  settlementDate: 6,
  conversionDate: 7,
};

const swiftTrackingOrderMap = {
  to: 0,
  chargeType: 1,
  senderAcknowledgementReceipt: 2,
  confirmedAmount: 3,
  instructedAmount: 4,
  deductions: 5,
  interbankSettlementAmount: 6,
};

function CancelTransactionContainer({
  transactionDetails,
  filters,
}: {
  transactionDetails: TransactionDetailsResponse;
  filters?: Partial<TransactionCriteria>;
}) {
  const { t } = useTranslation('transactions:details');
  const { locale } = useUser();
  const { mutateAsync: cancelConversion } = useCancelConversion({
    transaction: transactionDetails?.transaction,
    filters,
  });
  const { mutateAsync: cancelPayment } = useCancelPayment({
    transaction: transactionDetails?.transaction,
  });

  async function cancelTransaction() {
    if (transactionDetails.transaction?.action === 'payment') {
      try {
        const paymentId: string =
          transactionDetails?.payment?.id ||
          transactionDetails?.transaction?.relatedEntityId;
        await cancelPayment({
          id: paymentId,
        });
        toast.success(t('cancel.success.title'));
      } catch (err) {
        toast.error(t('errors.generic', { ns: 'common' }));
      }
    } else if (transactionDetails.transaction?.action === 'conversion') {
      try {
        const conversionId: string =
          transactionDetails?.conversion?.id ||
          transactionDetails?.transaction?.relatedEntityId;
        await cancelConversion({
          id: conversionId,
        });
        toast.success(t('cancel.success.title'));
      } catch (err) {
        toast.error(t('errors.generic', { ns: 'common' }));
      }
    }
  }

  const title = useMemo(() => {
    if (transactionDetails.transaction?.action === 'payment') {
      return t('cancel.payment.title');
    }
    return t('cancel.conversion.title');
  }, [transactionDetails, t]);

  const description = useMemo(() => {
    if (transactionDetails.transaction?.action === 'payment') {
      return t('cancel.payment.description');
    }

    return t('cancel.conversion.description');
  }, [transactionDetails, t]);

  const quoteDescription = useMemo(() => {
    const quote = transactionDetails.conversionCancellationQuote;
    const amount = quote
      ? new Money({
          amount: +quote.amount,
          currency: quote.currency,
          locale,
        }).format()
      : '';
    return amount
      ? t('cancel.conversion.quoteDescription', { quote: amount })
      : '';
  }, [transactionDetails, t, locale]);

  const cancelTransactionCopy = useMemo(() => {
    if (transactionDetails.transaction?.action === 'payment') {
      return t('cancel.actions.cancelPayment');
    }
    return t('cancel.actions.cancelConversion');
  }, [t, transactionDetails]);

  return (
    <Dialog>
      <DialogTrigger asChild onClick={(e) => e.stopPropagation()}>
        <Button variant="subtleDestructive">
          {t('actions.cancel', { ns: 'common' })}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {description}
          {quoteDescription && (
            <span className="mt-2 inline-block">{quoteDescription}</span>
          )}
        </DialogDescription>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild onClick={(e) => e.stopPropagation()}>
            <Button type="button" variant="outline">
              {t('actions.close', { ns: 'common' })}
            </Button>
          </DialogClose>
          <DialogClose asChild onClick={(e) => e.stopPropagation()}>
            <Button variant="subtleDestructive" onClick={cancelTransaction}>
              {cancelTransactionCopy}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function TransactionDetailsPage() {
  const { id } = useParams({ strict: false });
  const { filters } = useFilters('/index/transactions/$id');
  const { t } = useTranslation('transactions:details');
  const { locale, tz } = useUser();
  const { data, isLoading } = useGetTransactionDetails({
    id,
  });
  const transactionDetails = useMemo(() => data?.data, [data]);

  const title = useMemo(() => {
    if (transactionDetails?.transaction?.action === 'payment') {
      return (
        transactionDetails.paymentBeneficiaryGroup?.name ??
        transactionDetails?.transaction.relatedEntityShortReference
      );
    } else if (transactionDetails?.transaction?.action === 'conversion') {
      return `${transactionDetails.conversion?.buyCurrency} ${t('labels.to')} ${transactionDetails.conversion?.sellCurrency}`;
    }
    return transactionDetails?.transaction?.relatedEntityShortReference;
  }, [transactionDetails, t]);

  const transactionData = useMemo(() => {
    if (!transactionDetails?.transaction) {
      return null;
    }
    const transaction: TransactionResponse & {
      paymentReference?: string;
      paymentFee?: string;
      paymentStatus?: string;
    } = {
      ...transactionDetails?.transaction,
    };

    if (transactionDetails.payment) {
      transaction.paymentReference = transactionDetails?.payment?.reference;
      if (
        transactionDetails?.payment?.feeAmount &&
        transactionDetails?.payment?.feeCurrency
      ) {
        transaction.paymentFee = new Money({
          amount: +transactionDetails?.payment?.feeAmount,
          currency: transactionDetails?.payment?.feeCurrency,
          locale,
        }).format();
      }
      transaction.paymentStatus = transactionDetails?.payment?.status;
    }

    return Object.keys(transactionDetailOrderMap)
      .map((key, value) => {
        if (key === 'amount') {
          return {
            key: t(`labels.${key}`),
            value: new Money({
              amount: +transaction.amount,
              currency: transaction.currency,
              locale,
            }).format(),
          };
        }

        if (
          key === 'createdAt' ||
          key === 'settledAt' ||
          key === 'completedAt'
        ) {
          if (!transaction[key]) {
            return {
              key: t(`labels.${key}`),
              value: '',
            };
          }

          return {
            key: t(`labels.${key}`),
            value: new LocalDate({
              timestamp: transaction[key],
              locale,
              tz,
            }).format(),
          };
        }

        if (key === 'status') {
          return {
            key: t(`labels.${key}`),
            value: t(
              `values.status.${transaction.paymentStatus || transaction.status}`,
            ),
          };
        }

        if (key === 'action') {
          return {
            key: t(`labels.${key}`),
            value: t(`values.action.${transaction.action}`),
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (transaction as any)[key],
        };
      })
      .filter((item) => item.value);
  }, [transactionDetails, locale, t, tz]);

  const beneficiaryDetails = useMemo(() => {
    const beneficiaryData = transactionDetails?.paymentBeneficiary;
    if (!beneficiaryData) {
      return null;
    }
    return Object.keys(beneficiaryDetailsOrderMap)
      .map((key) => {
        if (key === 'beneficiaryType') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: t(`values.${beneficiaryData[key]}`, {
              ns: 'beneficiaries:details',
            }),
          };
        }

        if (key === 'beneficiaryAddress') {
          return {
            key: t(`labels.beneficiaryDetails.${key}`),
            value: `${(beneficiaryData[key] as unknown as string[]).join(',')}`,
          };
        }

        if (key === 'beneficiaryCountry') {
          return {
            key: t(`labels.beneficiaryDetails.${key}`),
            value: getCountryNameFromCode(beneficiaryData[key]),
          };
        }

        return {
          key: t(`labels.beneficiaryDetails.${key}`),
          value: (beneficiaryData as any)[key],
        };
      })
      .filter((item) => item.value);
  }, [transactionDetails, t]);

  const conversionDetails = useMemo(() => {
    const conversion = transactionDetails?.conversion;
    if (!conversion) {
      return null;
    }

    return Object.keys(conversionDetailsOrderMap).map((key) => {
      if (
        key === 'createdAt' ||
        key === 'settlementDate' ||
        key === 'conversionDate'
      ) {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: new LocalDate({
            timestamp: conversion[key],
            locale,
            tz,
          }).format(),
        };
      }

      if (key === 'status') {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: t(`labels.fxStatus.${conversion.status}`, {
            ns: 'currencyExchange',
          }),
        };
      }

      if (key === 'fixedSide') {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: t(`labels.conversionDetails.${conversion[key]}`),
        };
      }

      if (key === 'buyCurrency') {
        return {
          key: t(`labels.conversionDetails.buy`),
          value: new Money({
            amount: +conversion.clientBuyAmount,
            currency: conversion.buyCurrency,
            locale,
          }).format(),
        };
      }

      if (key === 'sellCurrency') {
        return {
          key: t(`labels.conversionDetails.sell`),
          value: new Money({
            amount: +conversion.clientSellAmount,
            currency: conversion.sellCurrency,
            locale,
          }).format(),
        };
      }

      if (key === 'clientRate') {
        const inverse = transactionDetails?.conversionInverse;
        const inverseLabel = inverse
          ? `(${t('labels.conversionDetails.inverse')}: ${inverse.toFixed(4)})`
          : '';

        return {
          key: t(`labels.conversionDetails.${key}`),
          value: `${conversion.clientRate} ${inverseLabel}`,
        };
      }

      return {
        key: t(`labels.conversionDetails.${key}`),
        value: (conversion as any)[key],
      };
    });
  }, [transactionDetails, locale, t, tz]);

  const isCancellable = useMemo(() => {
    return (
      (transactionDetails?.conversion &&
        transactionDetails?.conversion?.status === 'awaiting_funds') || // TODO ENUM
      (transactionDetails?.payment &&
        transactionDetails?.payment?.status === 'ready_to_send') // TODO ENUM
    );
  }, [transactionDetails]);

  const trackingDetails = useMemo(() => {
    const { paymentEvents, uetr, transactionStatus, ...generalDates } =
      transactionDetails?.paymentTracking || {};

    const lastEvent = paymentEvents?.[paymentEvents.length - 1];
    const tracking: any = { general: [] };

    if (transactionStatus) {
      tracking.general.push({
        key: t(`labels.status`),
        value: t(`values.status.${transactionStatus.status}`),
      });
      if (transactionStatus.reason) {
        tracking.general.push({
          key: t(`labels.swiftTracking.reason`),
          value: t(`values.reason.${transactionStatus.reason}`),
        });
      }
    }

    if (transactionStatus?.status === 'completed') {
      const dates = generalDates as Partial<CcPaymentTracking>;
      if (dates?.initiationTime && dates?.completionTime) {
        const start = DateTime.fromISO(dates.initiationTime);
        const end = DateTime.fromISO(dates.completionTime);
        const diff = end.diff(start, ['days', 'hours', 'minutes', 'seconds']);

        const filteredValues = Object.keys(diff.toObject())
          .filter((k) => (diff as any)[k] > 0)
          .reduce((acc, k) => {
            (acc as any)[k] = (diff as any)[k];
            return acc;
          }, {});
        const duration = Duration.fromObject(filteredValues).toObject();

        const labels = Object.keys(duration)
          .map((key) => {
            return t(`values.duration.${key}`, {
              count: (duration as any)[key],
            });
          })
          .join(', ');

        tracking.general.push({
          key: t(`labels.swiftTracking.duration`),
          value: labels,
        });
      }
    }

    if (lastEvent) {
      const lastUpdateTime = lastEvent.lastUpdateTime;
      if (lastUpdateTime) {
        tracking.general.push({
          key: t(`labels.swiftTracking.lastUpdatedTime`),
          value: new LocalDate({
            timestamp: lastUpdateTime,
            locale,
            tz,
          }).format(),
        });
      }
    }

    tracking.general.push({
      key: t(`labels.swiftTracking.uetr`),
      value: uetr,
    });

    const instructedAmount =
      paymentEvents && paymentEvents[0]?.instructedAmount
        ? paymentEvents[0]?.instructedAmount
        : null;
    if (instructedAmount) {
      tracking.general.push({
        key: t(`labels.swiftTracking.instructedAmount`),
        value: new Money({
          amount: +instructedAmount.amount,
          currency: instructedAmount.currency as SupportedCurrency,
          locale,
        }).format(),
      });
    }

    if (transactionStatus?.status === 'completed') {
      const creditedAmount = lastEvent ? lastEvent.confirmedAmount : null;
      if (creditedAmount) {
        tracking.general.push({
          key: t(`labels.swiftTracking.creditedAmount`),
          value: new Money({
            amount: +creditedAmount.amount,
            currency: creditedAmount.currency as SupportedCurrency,
            locale,
          }).format(),
        });
      }
    }

    const paymentEventsDetails = paymentEvents
      ?.sort(
        (a, b) =>
          new Date(a.lastUpdateTime).getTime() -
          new Date(b.lastUpdateTime).getTime(),
      )
      .map(({ trackerEventType, ...rest }: CcPaymentEvent) => {
        const name = t(`values.trackerEventType.${trackerEventType}`);

        const details = Object.keys(swiftTrackingOrderMap)
          ?.map((key: string) => {
            let value = '';
            let extra = '';

            if (
              key === 'instructedAmount' ||
              key === 'confirmedAmount' ||
              key === 'interbankSettlementAmount' ||
              key === 'chargeAmount'
            ) {
              if (rest[key] && rest[key].amount && rest[key].currency) {
                value = new Money({
                  amount: +rest[key].amount,
                  currency: rest[key].currency as SupportedCurrency,
                  locale,
                }).format();
              }
            } else if (key === 'transactionStatus') {
              value = t(`values.status.${rest[key].status}`);
              extra = rest[key].reason?.replace(/_/g, ' ');
            } else if (
              key === 'interbankSettlementDate' ||
              key === 'lastUpdateTime' ||
              key === 'senderAcknowledgementReceipt'
            ) {
              if (rest[key]) {
                value = new LocalDate({
                  timestamp: rest[key],
                  locale,
                  tz,
                }).format();
              }
            } else if (key === 'chargeType' && rest.chargeType) {
              value = t(`values.chargeType.${rest.chargeType}`);
            } else {
              value = (rest as any)[key];
            }

            return {
              key: t(`labels.swiftTracking.${key}`),
              value,
              details: extra,
            };
          })
          .filter((event) => event.value);

        const deductions =
          rest.instructedAmount?.amount &&
          rest.interbankSettlementAmount?.amount
            ? +rest.instructedAmount.amount -
              +rest.interbankSettlementAmount.amount
            : 0;

        if (rest.instructedAmount?.currency) {
          details.push({
            key: t(`labels.swiftTracking.deductions`),
            value: new Money({
              amount: deductions,
              currency:
                (rest.instructedAmount?.currency as SupportedCurrency) ?? '',
              locale,
            }).format(),
            details: '',
          });
        }

        return { name, from: rest.from, details };
      });

    tracking.paymentEvents = paymentEventsDetails;

    return tracking;
  }, [transactionDetails, t, locale, tz]);

  if (isLoading || !transactionDetails) {
    return (
      <PageWrapper className="flex flex-col gap-8">
        <Skeleton className="h-8 w-40 rounded-full" />
        <Skeleton className="h-8 w-80 rounded-full" />

        <Skeleton className="h-8 w-80 rounded-full" />
        <div className="flex flex-col gap-4">
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper className="flex flex-col">
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <Link
                to="/transactions"
                search={filters}
                className="cursor-pointer"
              >
                {t('title', { ns: 'transactions' })}
              </Link>
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <header className="mb-10 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{title}</h1>
          {transactionDetails.transaction?.status && (
            <TransactionStatusBadge
              status={transactionDetails?.transaction?.status}
            />
          )}
        </div>
        {transactionDetails && isCancellable && (
          <div className="flex items-center gap-3">
            <CancelTransactionContainer
              transactionDetails={transactionDetails}
              filters={filters}
            />
          </div>
        )}
      </header>
      <section className="mb-10 w-full 2xl:w-1/2">
        <h3 className="mb-4 text-xl font-medium">{t('receipt.title')}</h3>
        {transactionData && <DetailsCard data={transactionData} />}
      </section>
      {transactionDetails?.paymentBeneficiaryGroup && (
        <section className="mb-10 w-full 2xl:w-1/2">
          <h3 className="mb-4 text-xl font-medium">
            {t(`beneficiaryDetails.title`, {
              ns: 'beneficiaries:details',
            })}
          </h3>
          <DetailsCard
            data={[
              {
                key: t(`labels.beneficiaryDetails.name`),
                value: transactionDetails?.paymentBeneficiaryGroup?.name,
                href: `/beneficiaries/${transactionDetails?.paymentBeneficiaryGroup?.id}`,
              },
            ]}
          />
        </section>
      )}
      {beneficiaryDetails && (
        <section className="mb-10 w-full 2xl:w-1/2">
          <h3 className="mb-4 text-xl font-medium">
            {t(`bankAccountDetails.title`, {
              ns: 'beneficiaries:details',
            })}
          </h3>
          <DetailsCard data={beneficiaryDetails} />
        </section>
      )}

      {conversionDetails && (
        <section className="mb-10 w-full 2xl:w-1/2">
          <h3 className="mb-4 text-xl font-medium">{t('conversion.title')}</h3>
          <DetailsCard data={conversionDetails} />
        </section>
      )}
      {transactionDetails?.paymentTracking && (
        <section className="mb-10 w-full 2xl:w-1/2">
          <h3 className="mb-4 text-xl font-medium">{t(`tracking.title`)}</h3>
          {trackingDetails?.general && (
            <DetailsCard data={trackingDetails.general} />
          )}

          {trackingDetails?.paymentEvents && (
            <div className="mt-4">
              {trackingDetails.paymentEvents.map(
                (event: any, index: number) => (
                  <div className="group flex gap-2" key={`ev::${index}`}>
                    <div className="relative flex-col">
                      <div className="absolute top-2 h-[12px] w-[12px] rounded-full bg-slate-900"></div>
                      <div className="ml-1 h-full border-l-4 border-slate-300 group-first:mt-2"></div>
                    </div>
                    <div className="w-full pl-2">
                      <div>
                        <div>{event.from}</div>
                        <div className="text-xs leading-5 text-slate-500">
                          {event.name}
                        </div>
                      </div>

                      <DetailsCard
                        data={event?.details}
                        className="mb-4 group-last:mb-0"
                      />
                    </div>
                  </div>
                ),
              )}
            </div>
          )}
        </section>
      )}
    </PageWrapper>
  );
}

export default TransactionDetailsPage;
