import { useTranslation } from 'react-i18next';

import * as currencyFlags from './currency-assets';
import { Combobox, ComboboxItem } from './combobox';

export const currencies = [
  { icon: currencyFlags.USD, label: 'USD', value: 'USD' },
  { icon: currencyFlags.EUR, label: 'EUR', value: 'EUR' },
  { icon: currencyFlags.GBP, label: 'GBP', value: 'GBP' },
  { icon: currencyFlags.AED, label: 'AED', value: 'AED' },
  { icon: currencyFlags.BHD, label: 'BHD', value: 'BHD' },
  { icon: currencyFlags.AUD, label: 'AUD', value: 'AUD' },
  { icon: currencyFlags.CHF, label: 'CHF', value: 'CHF' },
  { icon: currencyFlags.CNY, label: 'CNY', value: 'CNY' },
  { icon: currencyFlags.CAD, label: 'CAD', value: 'CAD' },
  { icon: currencyFlags.DKK, label: 'DKK', value: 'DKK' },
  { icon: currencyFlags.CZK, label: 'CZK', value: 'CZK' },
  { icon: currencyFlags.HUF, label: 'HUF', value: 'HUF' },
  { icon: currencyFlags.HKD, label: 'HKD', value: 'HKD' },
  { icon: currencyFlags.JPY, label: 'JPY', value: 'JPY' },
  { icon: currencyFlags.KES, label: 'KES', value: 'KES' },
  { icon: currencyFlags.ILS, label: 'ILS', value: 'ILS' },
  { icon: currencyFlags.INR, label: 'INR', value: 'INR' },
  { icon: currencyFlags.KWD, label: 'KWD', value: 'KWD' },
  { icon: currencyFlags.MXN, label: 'MXN', value: 'MXN' },
  { icon: currencyFlags.NOK, label: 'NOK', value: 'NOK' },
  { icon: currencyFlags.PLN, label: 'PLN', value: 'PLN' },
  { icon: currencyFlags.OMR, label: 'OMR', value: 'OMR' },
  { icon: currencyFlags.NZD, label: 'NZD', value: 'NZD' },
  { icon: currencyFlags.QAR, label: 'QAR', value: 'QAR' },
  { icon: currencyFlags.RON, label: 'RON', value: 'RON' },
  { icon: currencyFlags.SAR, label: 'SAR', value: 'SAR' },
  { icon: currencyFlags.THB, label: 'THB', value: 'THB' },
  { icon: currencyFlags.SEK, label: 'SEK', value: 'SEK' },
  { icon: currencyFlags.SGD, label: 'SGD', value: 'SGD' },
  { icon: currencyFlags.UGX, label: 'UGX', value: 'UGX' },
  { icon: currencyFlags.TRY, label: 'TRY', value: 'TRY' },
  { icon: currencyFlags.ZAR, label: 'ZAR', value: 'ZAR' },
  { icon: currencyFlags.PHP, label: 'PHP', value: 'PHP' },
  { icon: currencyFlags.MYR, label: 'MYR', value: 'MYR' },
];

export interface Currency extends ComboboxItem {
  icon: string;
  value: string;
  label: string;
}

export interface CurrencyPickerProps {
  value?: string;
  onChange: (value: Currency | undefined) => void;
  label?: string;
  placeholder?: string;
}

export function CurrencyPicker({
  value,
  onChange,
  label,
  placeholder,
}: CurrencyPickerProps) {
  const { t } = useTranslation('common');

  return (
    <Combobox<Currency>
      items={currencies}
      emptyLabel={label || t('currency.empty')}
      placeholder={placeholder || t('currency.placeholder')}
      selectedItem={currencies.find((currency) => currency.value === value)}
      onSelect={onChange}
      resultClassName="max-h-[150px]"
    />
  );
}

export default CurrencyPicker;
