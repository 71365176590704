export const USD = '/assets/currencies/USD.png';
export const EUR = '/assets/currencies/EUR.png';
export const GBP = '/assets/currencies/GBP.png';
export const AED = '/assets/currencies/AED.png';
export const BHD = '/assets/currencies/BHD.png';
export const BGN = '/assets/currencies/BGN.png';
export const AUD = '/assets/currencies/AUD.png';
export const CHF = '/assets/currencies/CHF.png';
export const CNY = '/assets/currencies/CNY.png';
export const CAD = '/assets/currencies/CAD.png';
export const DKK = '/assets/currencies/DKK.png';
export const CZK = '/assets/currencies/CZK.png';
export const HUF = '/assets/currencies/HUF.png';
export const HKD = '/assets/currencies/HKD.png';
export const JPY = '/assets/currencies/JPY.png';
export const KES = '/assets/currencies/KES.png';
export const ILS = '/assets/currencies/ILS.png';
export const KWD = '/assets/currencies/KWD.png';
export const MXN = '/assets/currencies/MXN.png';
export const NOK = '/assets/currencies/NOK.png';
export const PLN = '/assets/currencies/PLN.png';
export const OMR = '/assets/currencies/OMR.png';
export const NZD = '/assets/currencies/NZD.png';
export const QAR = '/assets/currencies/QAR.png';
export const RON = '/assets/currencies/RON.png';
export const SAR = '/assets/currencies/SAR.png';
export const THB = '/assets/currencies/THB.png';
export const SEK = '/assets/currencies/SEK.png';
export const SGD = '/assets/currencies/SGD.png';
export const UGX = '/assets/currencies/UGX.png';
export const TRY = '/assets/currencies/TRY.png';
export const ZAR = '/assets/currencies/ZAR.png';
export const RUB = '/assets/currencies/RUB.png';
export const PHP = '/assets/currencies/PHP.png';
export const MYR = '/assets/currencies/MYR.png';
export const INR = '/assets/currencies/INR.png';
